/* @media (max-width: 400px) {
  .OwnedItems {
    max-height: 50%;
    overflow-y: scroll;
  }

} */



.OwnedItems {
  position: absolute;
  left: 10px;
  top: 90px;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  width: 360px;
  max-width: 100%;
  /* max-height: 50%; */
  max-height: calc(99% - 130px);
  overflow-y: scroll;
  padding-bottom: 180px;
}

.OwnedItems::-webkit-scrollbar {
  display: none;
}
.AttackButton {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    /* top: 65vh;
    right: 10px; */
    background: rgb(238, 78, 78);
    border-radius: 5px;
    border: 3px solid rgb(192, 45, 45);
    /* align-self: flex-end; */
    margin-top: auto;
    width: 45px;
    height: 45px;
}

.AttackButton:active {
    border: 3px solid rgb(238, 78, 78);
}

.AttackButton .damage-number {
    color: yellow;
    position: absolute;
    font-size: 17px;
    text-shadow: 0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black;
    bottom: 0;
    left: 5px;
}
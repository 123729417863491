.Tutorial {
    margin-top: 50vh;
    /* color: 'white'; */
    color: rgb(214, 214, 214);
    text-shadow: 0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    background-color: rgba(0, 0, 0, 0.842);
    padding: 5px;
    max-width: 80%;
    border-radius: 5px;
    z-index: 50;
}
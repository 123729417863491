.OwnedItem {
    display: flex;
    position: relative;
    width: fit-content;
}


.OwnedItem .icon-container {
    border: 1px solid gray;
    padding: 7px;
    border-radius: 5px;
    cursor: pointer;
    width: 48px;
    height: 48px;
}

.OwnedItem.common .icon-container {
    background-color: var(--common);
}

.OwnedItem.rare .icon-container {
    background-color: var(--rare);
}

.OwnedItem.legendary .icon-container {
    background-color: var(--legendary);
}

.OwnedItem .icon-container .amount {
    color: white;
    position: absolute;
    bottom: 0;
    right: 5px;
    font-size: large;
    text-shadow: 0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black;
}

.OwnedItem .description-box {
    display: none;
    /* display: flex; */
    z-index: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: antiquewhite;
    padding: 5px 10px;
    margin-left: 5px;
    border-radius: 5px;
    position: absolute;
    left: 50px;
    width: 300px;
    /* visibility: hidden; */
}

.OwnedItem:hover .description-box {
    /* visibility: visible; */
    display: flex;
}
:root {
  --common: green;
  --rare: #284cb1;
  --legendary: rgb(215 34 34);
}

/* rgb(205 47 47) */

.Game {
  position: relative;
  /* height: 100vh; */
  height: 100%;
  width: 100%;
  /* width: 100vw; */
  max-height: -webkit-fill-available;
  /* max-width: 57vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  user-select: none;
  z-index: 1;
}

.basic-button {
  background-color: rgb(233, 79, 79);
  border: 2px solid brown;
  color: white;
  font-weight: 500;
  border-radius: 5px;
  padding: 5px;
}

.basic-button:active {
  border: 2px solid rgb(187, 51, 51);
}
h1 {
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}


.WordDisp {
    flex-grow: 1;
    margin-top: auto;
    /* row-gap: 10px; */
    /* bottom: 35vh; */
}
.ItemChoice {
    display: flex;
    /* flex-direction: column; */
    background-color: antiquewhite;
    /* width: 70%; */
    width: 350px;
    cursor: pointer;
    border-radius: 10px;
    padding: 5px;
}

.item-choice-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
}

.item-choice-text-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.item-choice-title {
    font-weight: bold;
    /* text-decoration: ; */
}

.common .item-choice-title {
    color: var(--common);
}

.rare .item-choice-title {
    color: var(--rare);
}

.legendary .item-choice-title {
    color: var(--legendary);
}



.ItemChoice img {
    width: 32px;
    height: 32px;
}

.ItemChoice.selected {
    outline: 4px solid rgb(233, 79, 79);
    /* outline: 4px solid rgb(197, 184, 71); */
}
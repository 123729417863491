.TopHud {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* top: 50%; */
  left: 50%;
  transform: translate(-50%, 0);
  color: rgb(214, 214, 214);
  text-shadow: 0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
@media (max-width: 400px) {

    .dense .KeyItem {
        padding: 0.4rem 0.7rem;
        /* line-height: 1.4rem; */
        margin-left: 0.15rem;
        margin-right: 0.15rem;
    }

    .extra-dense .KeyItem {
        padding: 0.3rem 0.55rem;
        margin: 0.1rem;
        margin-left: 0.1rem;
        margin-right: 0.1rem;
        /* line-height: 1.4rem; */
    }
}
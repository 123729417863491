.Loss {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.418);
}

.loss-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 200px;
    width: 300px;
    /* background: rgb(133, 133, 133); */
    background-color: rgb(163 123 161);
    border-radius: 5px;
    /* color: rgb(240, 240, 240) */
}
.ItemSelection {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 15;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 20px;
    background-color: rgba(0, 0, 0, 0.418);
}

.choose-item {
    background-color: rgb(233, 79, 79);
    border: 2px solid brown;
    color: white;
    font-weight: 500;
    border-radius: 5px;
    padding: 5px;
}

.ItemSelection .title {
    font-size: 19px;
    color: #e5e5e5;
}
.ItemPopup {
    display: flex;
    flex-direction: column;
    /* background-color: rgba(218, 218, 218, 0.425); */
    align-items: center;
    justify-content: space-between;
    /* width: 70%; */
    height: 220px;
    /* width: 300px; */
    padding: 5px 0;
    border: 1px solid black;
    border-radius: 7px;
    background: #000000bf;
}

.ItemPopup .title {
    font-size: 23px;
}


@media (max-width: 400px) {

    .PopupKeyItem {
        padding: 0.2rem 0.6rem;
        /* line-height: 1.4rem; */
    }
}
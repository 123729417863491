.WordFlat {
  display: flex;
  align-items: center;
  /* column-gap: 3px; */
  height: 30px;
  color: rgb(218, 195, 195);
  text-shadow: 0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size: 26px;
}

.WordFlat.exists {
  color: #2bcb37;
}

@keyframes blink {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

}

.blinking-cursor {
  color: grey;
  display: inline;
  animation: blink 1s infinite ease-in-out;
}

.WordFlat .common-letter {
  color: rgb(226, 93, 76);
}
.HealthBar {
  background-color: rgb(219, 219, 219);
  /* border: 1px solid rgb(184, 184, 184); */
}

.healthbar-text span {
  color: white;
  text-shadow: 0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.health-background.current {
  background-color: rgb(238, 78, 78);

}

.health-background.incoming {
  background-color: rgb(238, 209, 78);
}

.border-radius-left-20 {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.border-radius-right-20 {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
.PotionDisplay {
    display: flex;
    flex-direction: row-reverse;
    position: relative;
    width: fit-content;
}

.PotionDisplay .icon-container {
    border: 1px solid gray;
    background-color: #b1005a;
    padding: 7px;
    border-radius: 5px;
    cursor: pointer;
    width: 48px;
    height: 48px;
}


.PotionDisplay.empty .icon-container {
    background-color: rgb(63, 63, 63);
    border: 1px solid gray;
}

.PotionDisplay .icon-container .uses {
    color: white;
    position: absolute;
    bottom: 0;
    right: 5px;
    font-size: large;
    text-shadow: 0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black;
}

.PotionDisplay .description-box {
    display: none;
    /* display: flex; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: antiquewhite;
    padding: 5px 10px;
    margin-left: 5px;
    border-radius: 5px;
    position: absolute;
    right: 48px;
    width: 300px;
    /* visibility: hidden; */
}

.PotionDisplay:hover .description-box {
    /* visibility: visible; */
    display: flex;
}

/* .item-choice-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
} */

.potion-text-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.potion-title {
    font-weight: bold;
    color: #b1005a;
    /* text-decoration: ; */
}

.PotionDisplay button {
    font-size: 15px;
}
.InputPopup {
    /* position: absolute; */
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 50;
    background-color: #00000077;
}


.input-popup-content {
    display: flex;
    flex-direction: column;
    /* background-color: rgba(218, 218, 218, 0.425); */
    align-items: center;
    justify-content: space-between;
    /* width: 70%; */
    height: 140px;
    /* width: 300px; */
    padding: 5px 0;
    /* border: 1px solid black; */
    /* border-radius: 7px; */
    /* background: #000000bf; */
}

.InputPopup .title {
    font-size: 23px;
    color: #e5e5e5;
}


@media (max-width: 400px) {

    .PopupKeyItem {
        padding: 0.2rem 0.5rem;
        /* line-height: 1.4rem; */
    }
}